@tailwind base;
@tailwind components;
@tailwind utilities;

.react-pdf__Document
  > .react-pdf__Page
  > .react-pdf__Page__annotations
  .annotationLayer {
  display: none !important;
  height: 0px !important;
}

.annotationLayer,
.textLayer {
  height: 0px !important;
  margin-bottom: 1rem;
  display: none !important;
}
/* Styling for the scrollbar thumb */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.layout {
  max-width: 94rem;
  /*  1440px */
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

body {
  background-color: #e5edf5;
  overflow-x: hidden !important;
}

.noPrint {
  display: none;
}
.dropdown:hover .dropdown-menu {
  display: block;
}

.alternate-bg tr:nth-child(even) {
  background: #f0f6fd;
}
.alternate-bg tr:nth-child(odd) {
  background: #ffffff;
}

.active div {
  background: rgb(49 112 181/1) !important;
  color: #fff;
}
tbody > tr:nth-child(even) {
  background-color: #fff;
}
tbody > tr:nth-child(odd) {
  background-color: #f7f9fbd1;
}

/* Hide scroll bar */

.noScrollBar::-webkit-scrollbar {
  display: none;
}

.noScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* loader */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.custom-select {
  background: #fff !important;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.heartbeat {
  animation: heartbeat 1.5s infinite;
}

.floating-button {
  /* position: fixed;
  bottom: 8px;
  right: 8px; */
  z-index: 9999;
}

.floating-button a {
  @apply bg-primary w-12 h-12 md:w-20 md:h-20 rounded-full flex justify-center items-center cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105 hover:ring-2 hover:ring-offset-2;
  animation: heartbeat 2s infinite;

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
}

.floating-button a img {
  width: 22px;
  height: 22px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}


/* custom scroll */
.customer-scroll::-webkit-scrollbar {
  height: 1px;
  border-radius: 4px;
  background: gray
}

.top-12 {
  width: 350px !important;
}

.top-12 > div {
  width: 100%;
  border-radius: 24px !important;
  margin-top: 8px;
}

.top-12 > div > div:nth-child(1) > div:last-child {
  display: none;
}
.top-12 > div > div:nth-child(2) > div:first-child {
  background-color: #F6F6F6;
  border-radius: 20px;
  padding: 0 4px !important;
  font-size: 16px;
  font-weight: bold;
}
.top-12 > div > div:nth-child(2) > div:last-child {
  width:  100%;
}
.top-12 > div > div:nth-child(2) > div:last-child > span {
  color: #BCBEC2;
  padding: 0 4px !important;
  font-size: 10px !important;
}

.top-12 > div > div:nth-child(2) > div:last-child > span:hover{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: orange;
}