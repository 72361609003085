@media print {
  body {
    margin: 0;
    padding: 0;
  }

  .card-page {
    page-break-after: always; /* Ensures each side is printed on a separate page */
  }

  button {
    display: none; /* Hides the print button on the printed page */
  }
}

/* Additional Styles */
.card-page {
  font-family: Arial, sans-serif;
  font-size: 12px;
}
